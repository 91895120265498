<!--
 * @Author: your name
 * @Date: 2020-04-23 16:12:30
 * @LastEditTime: 2020-12-03 10:00:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/useHelp/index.vue
-->

<template>
  <div class="preview-news">
    <div class="main">
      <div class="left">
        <h3>透明计算应用系列成果再出智慧医疗和智慧教育新品， 5G技术将催生更多应用场景</h3>
        <div class="news-setting">
          <h3 class="time">
            发布时间： 2019-03-31
          </h3>
          <!-- <h5 class="num">
            浏览量： 28289
          </h5> -->
        </div>
        <div class="img-box" style="margin-top:40px">
          <img src="../../assets/images/news/news12/news1.png" alt="">
        </div>
        <div class="news-content" style="margin:20px 0 40px">
          <p style="text-align:justify;">
            “透明计算”胶片打印机5秒钟可自助打印出CT片，通过透明计算银医通挂号、缴费、查询方便快捷……两年多前，
            中南大学、英特尔、湖南新云网科技有限公司联合在长沙发布全球首批“透明计算”系统产品，引发广泛关注。
            基于中国工程院院士张尧学“透明计算”理论研发的产品目前应用情况如何？推出了哪些产学研合作新品？
            前日，记者了解到，首批透明计算系统产品已得到广泛应用，新云网新推出的智慧健康亭和智慧课桌、智慧校牌等科技前沿产品也日益受到青睐。
            而5G时代的到来，更将助推透明计算系统产品应用到更多的场景中。
          </p>
          <p style="text-align:justify;font-weight:600">
            A 智慧健康亭：半小时出完整体检报告，家门口看名医
          </p>
          <p style="text-align:justify;">
            前日，记者在现场看到，智慧健康亭外观充满科技元素，内里“五脏俱全”，配备了相关检测仪器、智能传感器等。记者在验证身份后，
            根据语音提示进行了血压测量，测量结束后几秒钟就打印出了体检报告单，上面有“检测结果”“运动建议”“饮食建议”等，并自动上传到个人的健康档案。
          </p>
          <p style="text-align:justify;">
            记者了解到，除了对身高、体重、血压、心率等进行测量外，智慧健康亭还能测量血氧、人体成分、血红蛋白、血脂四项、血糖、尿酸等，
            体检项目涵盖14个大项36个小项。“出一份完整的体检报告只需半小时，通过透明计算健康大数据平台还能分析给出专业的饮食和用药建议，
            健康亭相当于是一家小型体检中心，或者说一名全科医生。”新云网副总经理杨建国介绍，健康亭的检查结果达到了大型专业医院的检测水准，
            但是价格要比医院便宜一半以上。体检报告出来后还可以在线传给专家，进行远程会诊和交流。
          </p>

          <p style="text-align:justify;">
            据介绍，智慧健康亭目前正在小区试点应用，未来将逐步推向社区和大型企业，居民在家门口可以完成体检外，还能预约挂号、在线看专家名医。
          </p>
          <p style="text-align:justify;">
            “基于‘透明计算’核心技术研发的智慧医联体有三大核心：应用终端、云平台、电子健康卡（电子健康码）。”
            杨建国告诉记者，即将推出的电子健康卡将实现全省看病一卡通，该卡捆绑了个人医保卡、门诊卡、病历、银行卡等，
            在省内医院看病，手机扫二维码就能完成挂号、缴费等所有流程。智慧医联体与区卫生平台、社康中心、本地医院互联互通，
            可以实现分级诊疗和家庭医生的数据采集、健康管理、慢病管理，大大缩短市民就诊时间。
          </p>
          <p style="text-align:justify;;font-weight:600">
            B 未来超级课堂：学生可进入细胞内部也可徜徉太空
          </p>
          <p style="text-align:justify;">
            今年初，张尧学院士在《网络计算的演变及应用展望》主题报告中，曾提到基于“透明计算”核心技术研发出的智慧校牌、智慧课桌和智慧黑板。
            “这么小的校牌可以干很多事情，可以打电话、可以定位，也可以把课堂作业从学校搬到家里，同时它避开了家长最讨厌的两个事情：一是微信，二是游戏。
            ”他还简单介绍了智慧课桌和智慧黑板，比如学生可以在智慧课桌上写作业，不用课本，还能实时与老师互动，使用过后不留痕迹，可以保证数据的安全。
          </p>
          <p style="text-align:justify;">
            前日，记者实地体验了这些产品。智慧课桌可以升降，桌面有显示器、底下有键盘，相当于一台电脑，桌面采用了防眩光处理，学生可以在上面画画、答题等。
            智慧黑板的功能更多，不用笔可正手写字、反手擦掉，字体和背景颜色可以定制，服务器中400多万套资源老师随时调用，且能随时同步在课桌上，每个学生的学情老师轻松掌握。
            在智慧黑板上点击播放音频教材更不在话下。
          </p>
        </div>

        <div class="pagination">
          <div class="a-more">
            <span @click="$router.push('/indexOs/newsDetails11')">上一条：中央媒体“长沙高质量发展调研行”启动</span>
            <span @click="$router.push('/indexOs/newsDetails13')">下一条：2019互联网岳麓峰会4月1号启幕：“互联网+教育” 让教育教学更智能</span>
          </div>
          <el-button plain @click="backGo()">
            返回
          </el-button>
        </div>
      </div>
      <!-- 搜索区域 -->
      <!-- <div class="search-right">
        <el-input
          v-model="keyWord"
          placeholder="查询你喜欢的内容"
          suffix-icon="el-icon-search"
        />
        <div class="hot-news">
          <div class="hot-title">
            <span>最热文章</span>
            <i class="hot-icon" />
          </div>
          <div class="lb-news">
            <router-link class="dg-news" to="/indexOs/newsDetails1">
              <img src="../../assets/images/news/news2-1.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  中共中央政治局常委、国务院副总理 韩正来我公司视察
                </h3>
                <h5 class="time">
                 2020-06-01
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails2">
              <img src="../../assets/images/news/news2-2.png">
              <div class="news-introduce">
                <h3 class="news-title">
                    岳麓山大学科技城：强化科技人才支撑 打造湖南创新...
                </h3>
                <h5 class="time">
                  2020-06-03
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails3">
              <img src="../../assets/images/news/news2-3.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  大科城软件产业联盟拍了拍
                </h3>
                <h5 class="time">
                  2020-08-03
                </h5>
              </div>
            </router-link>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewNews',
  data() {
    return {
      keyWord: ''
    };
  },
  methods: {
    backGo() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1400px) {
.preview-news{
  width: 100%;
  height: auto;
  background: #F5F5F5;
  .main{
    width: 800px;
    height: auto;
    margin: 0 auto;
    display: flex;
    padding-top: 58px;
    justify-content: space-between;
    .left{
      flex: 1;
      margin-right: 63px;
      h3{
        color: #313131;
        font-size:24px;
        font-weight: bolder;
        text-align: center;
      }
      .news-setting{
        color: #B5B5B5;
        display: flex;
         font-size:18px;
        .time{
          font-size:18px;
          color: #B5B5B5;
          font-weight: 100;
          width: 100%;
          text-align: center;
        }
        .num{
          font-size:18px;
          margin-left: 30px;
          font-weight: 100;
        }
      }
      .news-content{
        p{
          font-size: 18px;
          color: #313131;
          line-height: 32px;
          text-indent: 36px;
        }
        .img-explain{
          text-align:justify;
          text-align: center;
          font-size:16px;
        }
      }
      .img-box{
        width: 100%;
    display: flex;
    justify-content: center;
        img{
          width:800px;
        }
      }
    }
    .pagination{
      display: flex;
      justify-content: space-between;
      padding: 50px 0 150px;
      border-top: 1px solid #DDDDDD;
      .a-more{
        display: flex;
        flex-direction: column;
        font-size:18px;
        line-height:32px;
        color:rgba(49,49,49,1);
        span{
          display: inline-block;
          width:600px;
          white-space: nowrap;
          text-overflow:ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
      }
      .el-button{
        width: 134px;
        height: 48px;
        border: 1px solid rgba(253,156,2,1);
        opacity: 1;
        border-radius: 10px;
        font-size:24px;
        color:rgba(253,156,2,1);
      }
    }
    // 搜索区域
    .search-right{
      width: 334px;
      .el-input{
        width: 329px;
        height: 45px;
      }
      .el-input__inner{
        border-radius: 5px;
      }
      .el-input__icon{
        font-size: 24px;
      }
      .hot-news{
        .hot-title{
          font-size:24px;
          margin-bottom: 12px;
          color:#313131;
          .hot-icon{
            display: inline-block;
            background: url('../../assets/images/news/hot.png');
            width: 18px;
            height: 22px;
            margin-left: 6px;
          }
        }
        .dg-news{
          display: flex;
          margin-bottom: 22px;
          img{
            width:132px;
            height:76px;
          }
          .news-introduce{
            flex: 1;
            margin-left: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .news-title{
              font-size:16px;
              font-weight: 600;
              color: #313131;
              box-sizing: border-box;
              text-overflow:ellipsis;
              display:-webkit-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;
              overflow: hidden;
            }
            .time{
              font-size:18px;
              color: #B5B5B5;
              font-weight: 100;
            }
          }
          &:hover{
            .news-title{
              color:rgba(253,156,2,1);
            }
          }
        }
      }
    }
  }
}
}
@media screen and (max-width: 1400px) {
  .preview-news{
    width: 100%;
    height: auto;
    background: #fff;
    .main{
      width: 100%;
      height: auto;
      display: flex;
      padding-top: 1rem;
      justify-content: space-between;
      padding:1.07rem .48rem;
      .left{
        flex: 1;
        h3{
          color: #313131;
          font-size:.48rem;
          font-weight: bolder;
          text-align: center;
        }
        .news-setting{
          color: #B5B5B5;
          display: flex;
          font-size:18px;
          .time{
            font-size:.267rem;
            color: #B5B5B5;
            font-weight: 100;
            width: 100%;
            text-align: center;
          }
          .num{
            font-size:18px;
            margin-left: 30px;
            font-weight: 100;
          }
        }
        .news-content{
          width: 100%;
          p{
            font-size: .373rem;
            color: #313131;
            line-height: .7rem;
            text-indent: .747rem;
          }
          .img-explain{
            text-align:justify;
            text-align: center;
            font-size:.267rem;
          }
        }
        .img-box{
          width: 100%;
          display: flex;
          justify-content: center;
          img{
            width:100%;
            height: 100%;
          }
        }
      }
      .pagination{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: .53rem;
        .a-more{
          display: flex;
          flex-direction: column;
          font-size:.32rem;
          span{
            display: inline-block;
            width:8.933rem;
            cursor: pointer;
            color:rgba(49,49,49,1);
            height: auto;
            padding:.267rem .32rem;
            border: 1px solid #8A8684;
            border-radius: .267rem;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;
            margin-bottom: .48rem;
          }
        }
        .el-button{
          width: 100%;
          font-size:.427rem;
          border: 1px solid rgba(253,156,2,1);
          padding:.267rem .32rem;
          border-radius: .267rem;
          color:rgba(253,156,2,1);
        }
      }
      // 搜索区域
      // .search-right{
      //   width: 334px;
      //   .el-input{
      //     width: 329px;
      //     height: 45px;
      //   }
      //   .el-input__inner{
      //     border-radius: 5px;
      //   }
      //   .el-input__icon{
      //     font-size: 24px;
      //   }
      //   .hot-news{
      //     .hot-title{
      //       font-size:24px;
      //       margin-bottom: 12px;
      //       color:#313131;
      //       .hot-icon{
      //         display: inline-block;
      //         background: url('../../assets/images/news/hot.png');
      //         width: 18px;
      //         height: 22px;
      //         margin-left: 6px;
      //       }
      //     }
      //     .dg-news{
      //       display: flex;
      //       margin-bottom: 22px;
      //       img{
      //         width:132px;
      //         height:76px;
      //       }
      //       .news-introduce{
      //         flex: 1;
      //         margin-left: 8px;
      //         display: flex;
      //         flex-direction: column;
      //         justify-content: space-between;

      //         .news-title{
      //           font-size:16px;
      //           font-weight: 600;
      //           color: #313131;
      //           box-sizing: border-box;
      //           text-overflow:ellipsis;
      //           display:-webkit-box;
      //           -webkit-box-orient:vertical;
      //           -webkit-line-clamp:2;
      //           overflow: hidden;
      //         }
      //         .time{
      //           font-size:18px;
      //           color: #B5B5B5;
      //           font-weight: 100;
      //         }
      //       }
      //       &:hover{
      //         .news-title{
      //           color:rgba(253,156,2,1);
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}
</style>
